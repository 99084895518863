import "../Presentation/presentation.css"
import Back from "../../components/Back/back";
import back from "../../assets/back.png"
import presentation from "../../assets/presentation.png"

function Presentation () {
return(
<div className="presentation-container">
   
        <div className="presentation-title">
        <Back title="Derrière Laulie web se cache :" image={back}/>
        </div> 
        <div className="presentation-texte">
        <div className="presentation-photo"><img src={presentation} alt="site internet tourcoing" /></div>
        <div className="presentation-info">
            <p>
            Leslie, 35 ans, passionnée par le développement web. Après 15 ans dans le rugby, où j'ai acquis des compétences en équipe, discipline et persévérance, j'ai décidé de faire de ma passion ma profession. Ces valeurs sportives me permettent de comprendre les besoins des clients et de collaborer étroitement pour développer leur visibilité en ligne. Proactive et à l'affût des tendances technologiques, je propose des solutions web modernes et performantes. Si vous cherchez une développeuse web déterminée et orientée client, je serais ravie d'échanger sur la manière dont je peux contribuer à vos projets</p></div>
    </div>
</div>
)
}
export default Presentation